<template>
<div class="body">
    <ScrollPanel style="width: 100%; height: 100%" class="scrollbar">
        <Menubar :model="items">
            <template #start>
                <img alt="logo" :src="require(`../../assets/images/logo/logo-dark.png`)" height="30" class="p-mr-2" />
            </template>
        </Menubar>
        <div>
            <transition name="fade" mode="out-in">
                <router-view />
            </transition>
        </div>
    </ScrollPanel>
</div>
</template>

<script>
export default {
    data() {
        return {
            items: [{
                    label: "Anasayfa",
                    icon: "pi pi-fw pi-home",
                    to: "/Admin/Main",
                },
                {
                    label: "Fiyat Teklif",
                    icon: "pi pi-fw pi-user",
                    items: [{
                            label: "Bekleyen",
                            icon: "pi pi-fw pi-list",
                            to: "/Admin/FiyatTeklif_Bekleyen",
                        },
                        {
                            label: "Okunan",
                            icon: "pi pi-fw pi-list",
                            to: "/Admin/FiyatTeklif_Okundu",
                        },
                    ],
                },
                {
                    label: "Blog",
                    icon: "pi pi-fw pi-user",
                    items: [{
                            label: "Tüm Yazılar",
                            icon: "pi pi-fw pi-folder-open",
                            to: "/Blog/TumYazilar",
                        },
                        {
                            separator: true,
                        },
                        {
                            label: "Yeni Yazı",
                            icon: "pi pi-fw pi-pencil",
                            to: "/Blog/YeniYazi",
                        },
                    ],
                },
                {
                    label: "Kullanıcı",
                    icon: "pi pi-fw pi-user",
                    items: [{
                            label: "Kullanıcılar",
                            icon: "pi pi-fw pi-list",
                            to: "/Admin/Kullanicilar",
                        },
                    ],
                },
                // {
                //     label: "Yönetim",
                //     icon: "pi pi-fw pi-users",
                //     items: [{
                //             label: "Rol",
                //             icon: "pi pi-fw pi-list",
                //             to: "/Admin/Rol",
                //         },
                //         {
                //             label: "Yetki",
                //             icon: "pi pi-fw pi-list",
                //             to: "/Admin/Yetki",
                //         },
                //         {
                //             separator: true,
                //         },
                //         {
                //             label: "Web Sayfaları",
                //             icon: "pi pi-fw pi-list",
                //             to: "/Admin/WebSayfa",
                //         },
                //         {
                //             separator: true,
                //         },
                //         {
                //             label: "Fiyatlandırma",
                //             icon: "pi pi-fw pi-list",
                //             to: "/Admin/Fiyatlandirma/Kurye",
                //         },
                //         {
                //             separator: true,
                //         },
                //         {
                //             label: "Tüm Talepler",
                //             icon: "pi pi-fw pi-list",
                //             to: "/Admin/TumTalepler",
                //         },
                //     ],
                // },
                {
                    label: "Quit",
                    icon: "pi pi-fw pi-power-off",
                    to: "/Cikis"
                }
            ],
            chartData: {
                labels: ["A", "B", "C"],
                datasets: [{
                    data: [300, 50, 100],
                    backgroundColor: ["#42A5F5", "#66BB6A", "#FFA726"],
                    hoverBackgroundColor: ["#64B5F6", "#81C784", "#FFB74D"],
                }, ],
            },
            lightOptions: {
                plugins: {
                    legend: {
                        labels: {
                            color: "#495057",
                        },
                    },
                },
            },
        };
    }
};
</script>

<style scoped>
.body {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 10;
    background-color: var(--gray-300);
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}


::v-deep(.p-scrollpanel.scrollbar .p-scrollpanel-wrapper) {
    border-right: 10px solid var(--surface-ground);
}

::v-deep(.p-scrollpanel.scrollbar .p-scrollpanel-bar) {
    background-color: var(--orange-500);
    opacity: 1;
    transition: background-color 0.3s;
}

::v-deep(.p-scrollpanel.scrollbar .p-scrollpanel-bar:hover) {
    background-color: var(--teal-400);
}
</style>