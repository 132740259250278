import axios from 'axios';
import { UserData } from '../store/user.store';

class AuthService {
  login(user) {
    let data = 'grant_type=password&username=' + user.username + '&password=' + user.password
    return axios({
      method: 'POST',
      url: this.checkURL() + 'login',
      //url: 'https://localhost:44386/api/' + 'login',
      headers: {
        withCredentials: true,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: (data)
    }).then((response) => {
      if (response.status === 200) {
        UserData.dispatch('setaccessToken', response.data.access_token);
      }
      else {
        //TODO:  login failed alert yapılacak 
      }
      return response.data;
    })
  }

  logout() {
    return axios({
      method: 'GET',
      url: this.checkURL() + 'logout'
    }).then((response) => {
      if (response.status === 200) {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
      }
      return response.data;
    })
  }

  checkURL() {
    let TokenURL = null;
    if (process.env.NODE_ENV === 'production') {

      TokenURL = 'https://api.eksperenerji.com/api/'
    }
    else {
      TokenURL = 'https://localhost:44386/api/'
    }
    return TokenURL;
  }

  // register(user) {
  //   return axios.post(API_URL + 'signup', {
  //     username: user.username,
  //     email: user.email,
  //     password: user.password
  //   });
  // }
}

export default new AuthService();