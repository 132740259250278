<template>
  <div id="app">
    <Toast />
    <router-view  />
 </div>
</template>

<script>

export default {
  name: 'App',
  data (){
    return{
    }
  },
  components: {
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  height: 100%;
  width: 100%;

}

</style>
