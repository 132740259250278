import { createWebHistory, createRouter } from "vue-router";
import AdminLayout from "./components/Layouts/AdminLayout.vue";
import PublicLayout from "./components/Layouts/PublicLayout.vue";
import AuthLayout from "./components/Layouts/AuthLayout.vue";


const routes = [
    { path: "/", redirect: "/tr/Anasayfa" },
    {
        path: "/",
        name: "",
        component: PublicLayout,
        children: [{
            path: "/tr/Anasayfa",
            name: "Anasayfa",
            component: () =>
                import("./components/PublicPage/Anasayfa.vue")
        },
        {
            path: "/tr/SirketProfil",
            name: "SirketProfil",
            component: () =>
                import("./components/PublicPage/SubPages-TR/SirketProfil.vue")
        },
        {
            path: "/tr/Blog",
            name: "_Blog",
            component: () =>
                import("./components/PublicPage/SubPages-TR/Blog.vue")
        },
        {
            path: "/tr/iletisim",
            name: "_iletisim",
            component: () =>
                import("./components/PublicPage/SubPages-TR/iletisim.vue")
        },
        {
            path: "/tr/FiyatTeklif",
            name: "FiyatTeklif",
            component: () =>
                import("./components/PublicPage/SubPages-TR/FiyatTeklif.vue")
        },
        {
            path: "/tr/YediAdim",
            name: "YediAdim",
            component: () =>
                import("./components/PublicPage/SubPages-TR/YediAdim.vue")
        },
        {
            path: "/tr/Hizmetler/ElektrikliAracSarjIstasyonu",
            name: "ElektrikliAracSarjIstasyonu",
            component: () =>
                import("./components/PublicPage/SubPages-TR/Hizmetler/ElektrikliAracSarjIstasyonu.vue")
        },
        {
            path: "/tr/Hizmetler/SebekedenBagimsizSistemler",
            name: "SebekedenBagimsizSistemler",
            component: () =>
                import("./components/PublicPage/SubPages-TR/Hizmetler/SebekedenBagimsizSistemler.vue")
        },
        {
            path: "/tr/Hizmetler/SebekeyeBagliSistemler",
            name: "SebekeyeBagliSistemler",
            component: () =>
                import("./components/PublicPage/SubPages-TR/Hizmetler/SebekeyeBagliSistemler.vue")
        },
        {
            path: "/tr/Hizmetler/HibritGunesEnerjisiSistemleri",
            name: "HibritGunesEnerjisiSistemleri",
            component: () =>
                import("./components/PublicPage/SubPages-TR/Hizmetler/HibritGunesEnerjisiSistemleri.vue")
        }, 
        {
            path: "/tr/Hizmetler/GunesEnerjisiileTarimsalSulamaSistemleri",
            name: "GunesEnerjisiileTarimsalSulamaSistemleri",
            component: () =>
                import("./components/PublicPage/SubPages-TR/Hizmetler/GunesEnerjisiileTarimsalSulamaSistemleri.vue")
        },{
            path: "/tr/Hizmetler/MobilGESUygulamalari",
            name: "MobilGESUygulamalari",
            component: () =>
                import("./components/PublicPage/SubPages-TR/Hizmetler/MobilGESUygulamalari.vue")
        },
        ]
    },
    {
        path: "/en",
        name: "",
        component: PublicLayout,
        children: [{
            path: "/en/MainPage",
            name: "MainPage",
            component: () =>
                import("./components/PublicPage/MainPage.vue")
        },
        {
            path: "/en/CompayProfile",
            name: "CompayProfile",
            component: () =>
                import("./components/PublicPage/SubPages-EN/CompayProfile.vue")
        },
        {
            path: "/en/Blog",
            name: "Blog",
            component: () =>
                import("./components/PublicPage/SubPages-EN/Blog.vue")
        },
        {
            path: "/en/iletisim",
            name: "iletisim",
            component: () =>
                import("./components/PublicPage/SubPages-EN/iletisim.vue")
        },
        {
            path: "/en/PriceOffer",
            name: "PriceOffer",
            component: () =>
                import("./components/PublicPage/SubPages-EN/PriceOffer.vue")
        },
        {
            path: "/en/SevenStep",
            name: "SevenStep",
            component: () =>
                import("./components/PublicPage/SubPages-EN/SevenStep.vue")
        },
        {
            path: "/en/Hizmetler/ElektrikliAracSarjIstasyonu",
            name: "_ElektrikliAracSarjIstasyonu",
            component: () =>
                import("./components/PublicPage/SubPages-EN/Hizmetler/ElektrikliAracSarjIstasyonu.vue")
        },
        {
            path: "/en/Hizmetler/SebekedenBagimsizSistemler",
            name: "_SebekedenBagimsizSistemler",
            component: () =>
                import("./components/PublicPage/SubPages-EN/Hizmetler/SebekedenBagimsizSistemler.vue")
        },
        {
            path: "/en/Hizmetler/SebekeyeBagliSistemler",
            name: "_SebekeyeBagliSistemler",
            component: () =>
                import("./components/PublicPage/SubPages-EN/Hizmetler/SebekeyeBagliSistemler.vue")
        },
        {
            path: "/en/Hizmetler/HibritGunesEnerjisiSistemleri",
            name: "_HibritGunesEnerjisiSistemleri",
            component: () =>
                import("./components/PublicPage/SubPages-EN/Hizmetler/HibritGunesEnerjisiSistemleri.vue")
        }, 
        {
            path: "/en/Hizmetler/GunesEnerjisiileTarimsalSulamaSistemleri",
            name: "_GunesEnerjisiileTarimsalSulamaSistemleri",
            component: () =>
                import("./components/PublicPage/SubPages-EN/Hizmetler/GunesEnerjisiileTarimsalSulamaSistemleri.vue")
        },{
            path: "/en/Hizmetler/MobilGESUygulamalari",
            name: "_MobilGESUygulamalari",
            component: () =>
                import("./components/PublicPage/SubPages-EN/Hizmetler/MobilGESUygulamalari.vue")
        }
        ]
    },

    { path: "/tr/Projeler", redirect: "/tr/Projeler/Hepsi" },
    {
        path: "/",
        name: "Projeler",
        component: PublicLayout,
        children: [
            {
                path: "/tr/Projeler/BagEvi_HobiEvi",
                name: "BagEvi_HobiEvi",
                component: () =>
                    import("./components/PublicPage/SubPages-TR/Projeler/Proje_BagEvi_HobiEvi.vue")
            },
            {
                path: "/tr/Projeler/ElektrikliAracSarj_Carport",
                name: "ElektrikliAracSarj_Carport",
                component: () =>
                    import("./components/PublicPage/SubPages-TR/Projeler/Proje_ElektrikliAracSarj_Carport.vue")
            },
            {
                path: "/tr/Projeler/Konut_Mesken",
                name: "Konut_Mesken",
                component: () =>
                    import("./components/PublicPage/SubPages-TR/Projeler/Proje_Konut_Mesken.vue")
            },
            {
                path: "/tr/Projeler/SehirMobilyalari",
                name: "SehirMobilyalari",
                component: () =>
                    import("./components/PublicPage/SubPages-TR/Projeler/Proje_SehirMobilyalari.vue")
            },
            {
                path: "/tr/Projeler/Tir_Karavan",
                name: "Tir_Karavan",
                component: () =>
                    import("./components/PublicPage/SubPages-TR/Projeler/Proje_Tir_Karavan.vue")
            }
        ]
    },
    { path: "/en/Projeler", redirect: "/en/Projeler/Hepsi" },
    {
        path: "/",
        name: "_Projeler",
        component: PublicLayout,
        children: [
            {
                path: "/en/Projeler/BagEvi_HobiEvi",
                name: "_BagEvi_HobiEvi",
                component: () =>
                    import("./components/PublicPage/SubPages-EN/Projeler/Proje_BagEvi_HobiEvi.vue")
            },
            {
                path: "/en/Projeler/ElektrikliAracSarj_Carport",
                name: "_ElektrikliAracSarj_Carport",
                component: () =>
                    import("./components/PublicPage/SubPages-EN/Projeler/Proje_ElektrikliAracSarj_Carport.vue")
            },
            {
                path: "/en/Projeler/Konut_Mesken",
                name: "_Konut_Mesken",
                component: () =>
                    import("./components/PublicPage/SubPages-EN/Projeler/Proje_Konut_Mesken.vue")
            },
            {
                path: "/en/Projeler/SehirMobilyalari",
                name: "_SehirMobilyalari",
                component: () =>
                    import("./components/PublicPage/SubPages-EN/Projeler/Proje_SehirMobilyalari.vue")
            },
            {
                path: "/en/Projeler/Tir_Karavan",
                name: "_Tir_Karavan",
                component: () =>
                    import("./components/PublicPage/SubPages-EN/Projeler/Proje_Tir_Karavan.vue")
            }
        ]
    },
    {
        path: "/tr/BlogDetay/:BlogNo",
        name: "",
        component: PublicLayout,
        children: [{
            path: "/tr/BlogDetay",
            name: "BlogDetay",
            component: () =>
                import("./components/PublicPage/SubPages-TR/BlogDetay.vue")
        }]
    },

    { path: "/Auth", redirect: "/Auth/Login" },
    {
        path: "/Auth",
        name: "",
        component: AuthLayout,
        children: [{
            path: "/Auth/Login",
            name: "Login",
            component: () =>
                import("./components/PublicPage/Auth/Login.vue"),
        },
        {
            path: "/Auth/Signup",
            name: "Signup",
            component: () =>
                import("./components/PublicPage/Auth/Signup.vue")
        }
        ]
    },
    { path: "/Admin", redirect: "/Admin/Main" },
    {
        path: "/Admin",
        name: "",
        component: AdminLayout,
        children: [
            {
                path: "/Admin/Main",
                name: "Main",
                component: () =>
                    import("./components/PrivatePage/Pages/AdminSayfasi.vue")
            },
            {
                path: "/Admin/Kullanicilar",
                name: "Kullanicilar",
                component: () =>
                    import("./components/PrivatePage/KullaniciSayfalari/KullanicilarSayfasi.vue")
            }, 
            {
                path: "/Admin/Rol",
                name: "Rol",
                component: () =>
                    import("./components/PrivatePage/Yönetim/Rol.vue")
            },
             {
                path: "/Admin/Yetki",
                name: "Yetki",
                component: () =>
                    import("./components/PrivatePage/Yönetim/Yetki/Yetki.vue")
            }, 
            {
                path: "/Admin/WebSayfa",
                name: "WebSayfa",
                component: () =>
                    import("./components/PrivatePage/Yönetim/Yetki/WebSayfa.vue")
            }, 
            {
                path: "/Admin/FiyatTeklif_Bekleyen",
                name: "FiyatTeklif_Bekleyen",
                component: () =>
                    import("./components/PrivatePage/FiyatTeklif/FiyatTeklif_Bekleyen.vue")
            }, {
                path: "/Admin/FiyatTeklif_Okundu",
                name: "FiyatTeklif_Okundu",
                component: () =>
                    import("./components/PrivatePage/FiyatTeklif/FiyatTeklif_Okundu.vue")
            }
        ]
    },
    {
        path: "/Blog",
        name: "",
        component: AdminLayout,
        children: [
            {
                path: "/Blog/YeniYazi",
                name: "YeniYazi",
                component: () =>
                    import("./components/PrivatePage/Pages/Blog/YeniYazi.vue")
            },
            {
                path: "/Blog/TumYazilar",
                name: "TumYazilar",
                component: () =>
                    import("./components/PrivatePage/Pages/Blog/TumYazilar.vue")
            },
        ]
    },
    { 
        path: '/enerjisepeti',
        beforeEnter(to, from, next) {
            window.location.replace("https://enerjisepeti.com")
        }
    },
    {
        path: "/NotFound",
        component: () =>
            import("./components/PublicPage/ErrorPages/NotFound.vue"),
    },
    {
        path: "/Cikis",
        component: () =>
            import("./components/PublicPage/Auth/Cikis.vue"),
    },
];



const router = createRouter({
    history: createWebHistory(),
    routes,
});

import GlobalServis from '../src/services/GlobalServis';
import index from './store/index'

router.beforeEach((to, from, next) => {
    console.log(to.href);
    if (
        to.href == '/enerjisepeti'                                                                  ||
        // TR
        to.href == '/tr/Anasayfa'                                                                   || to.href == '/tr/FiyatTeklif'                                                             ||
        to.href == '/tr/SirketProfil'                                                               || to.href == '/tr/YediAdim'                                                                ||
        to.href == '/tr/Projeler/BagEvi_HobiEvi'                                                    || to.href == '/tr/Projeler/Hepsi'                                                          || 
        to.href == '/tr/Projeler/ElektrikliAracSarj_Carport'                                        || to.href == '/tr/Projeler/Tir_Karavan'                                                    || 
        to.href == '/tr/Projeler/Konut_Mesken'                                                      || to.href == '/tr/Projeler/SehirMobilyalari'                                               ||
        to.href == '/tr/Blog'                                                                       || to.href == '/tr/BlogDetay'                                                               ||
        to.href == '/tr/iletisim'                                                                   ||  
        to.href == '/tr/Hizmetler/ElektrikliAracSarjIstasyonu'                                      || to.href == '/tr/Hizmetler/SebekedenBagimsizSistemler'                                    ||
        to.href == '/tr/Hizmetler/SebekeyeBagliSistemler'                                           || to.href == '/tr/Hizmetler/HibritGunesEnerjisiSistemleri'                                 ||
        to.href == '/tr/Hizmetler/GunesEnerjisiileTarimsalSulamaSistemleri'                         || to.href == '/tr/Hizmetler/MobilGESUygulamalari'                                          ||
        // EN
        to.href == '/en/MainPage'                                                                   || to.href == '/en/PriceOffer'                                                              ||
        to.href == '/en/CompayProfile'                                                              || to.href == '/en/SevenStep'                                                               ||
        to.href == '/en/Projeler/BagEvi_HobiEvi'                                                    || to.href == '/en/Projeler/Hepsi'                                                          || 
        to.href == '/en/Projeler/ElektrikliAracSarj_Carport'                                        || to.href == '/en/Projeler/Tir_Karavan'                                                    || 
        to.href == '/en/Projeler/Konut_Mesken'                                                      || to.href == '/en/Projeler/SehirMobilyalari'                                               ||
        to.href == '/en/Blog'                                                                       || to.href == '/en/BlogDetay'                                                               || 
        to.href == '/en/iletisim'                                                                   ||        
        to.href == '/en/Hizmetler/ElektrikliAracSarjIstasyonu'                                      || to.href == '/en/Hizmetler/SebekedenBagimsizSistemler'                                    ||
        to.href == '/en/Hizmetler/SebekeyeBagliSistemler'                                           || to.href == '/en/Hizmetler/HibritGunesEnerjisiSistemleri'                                 ||
        to.href == '/en/Hizmetler/GunesEnerjisiileTarimsalSulamaSistemleri'                         || to.href == '/en/Hizmetler/MobilGESUygulamalari'                                          ||
        // Global
        to.href == '/Auth/Login'
    ) {
        next();
    } else {
        let data = {
            URL: to.href
        }
        GlobalServis.GlobalServis("POST", "GetYetkiKontrol", data).then(
            (res) => {
                if (res.status == 200 && res.data == true) {
                    next();
                } else {
                    if (index.state.auth.status.loggedIn) {
                        next('/NotFound');
                    } else {
                        next('/tr/Anasayfa');
                    }
                }
            }
        );
    }
})

router.afterEach(() => {
})

export default router;