import Vuex from 'vuex'

export const UserData = new Vuex.Store({
  state: {
    token: localStorage.getItem('token'),
    // userName: localStorage.getItem('userName'),
    // token_type: localStorage.getItem('token_type'),
    // expires_in: localStorage.getItem('expires_in'),
  },
  actions: {
    setaccessToken ({ commit }, Value) {
      localStorage.setItem('token', Value)
      commit('_TOKEN', Value)
    },
    // setuserName ({ commit }, Value) {
    //   localStorage.setItem('userName', Value)
    //   commit('_USERNAME', Value)
    // },
    // settoken_type ({ commit }, Value) {
    //   localStorage.setItem('token_type', Value)
    //   commit('_TOKEN_TYPE', Value)
    // },
    // setexpires_in ({ commit }, Value) {
    //   localStorage.setItem('expires_in', Value)
    //   commit('_EXPIRES_IN', Value)
    // },
  },
  mutations: {
    _TOKEN (state, Value) { state.token = Value },
    // _USERNAME (state, Value) { state.userName = Value },
    // _TOKEN_TYPE (state, Value) { state.token_type = Value },
    // _EXPIRES_IN (state, Value) { state.expires_in = Value },
  },
  getters: {
    getuserData: state => { return state.token },
    // getuserName: state => { return state.userName },
    // gettoken_type: state => { return state.token_type },
    // getexpires_in: state => { return state.expires_in },
  }

})