import axios from 'axios';
import { UserData } from '../store/user.store';

class GlobalServis {
  GlobalServis(Type, URL, DemandData) {
    let ServiceURL = null;
    if (process.env.NODE_ENV === 'production') {

      // app.config.globalProperties.TokenURL = 'https://api.gelsinteknik.com/'
      ServiceURL = 'https://api.eksperenerji.com/api/'
    }
    else {
      // app.config.globalProperties.TokenURL = 'https://localhost:44386/'
      ServiceURL = 'https://localhost:44386/api/'
    }

    return axios({
      method: Type.toUpperCase(),
      data: DemandData,
      headers: {
        'api-version': '1.0',
        contentType: 'application/x-www-form-urlencoded',
        Authorization: 'bearer ' + UserData.getters.getuserData,
      },
      url: ServiceURL + URL,
      //url: 'https://localhost:44386/api/' + URL,
    }).then((response) => {
      return response;
    }).catch(function (error) {
      return error;
    })
  }

}

export default new GlobalServis();

